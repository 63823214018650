import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";

import "./fonts.css";

type ThumbnailColors = {
  thumbnail: {
    highlightActive: string;
    bgcolorImg: string;
    border: string;
  };
};

type CustomPalette = PaletteOptions & ThumbnailColors;

export const customPalette = {
  primary: {
    main: "#ffdd10", //a lot usage
    light: "#ececec", //BOM, TabsBar, Checkbox(unchecked)
    dark: "#e9c800", //
    contrastText: "#111", //Button font color, Tab font color, activeTab font color,
  },
  secondary: {
    main: "#707070", // PopoverIcon
    light: "#c1c1c1", //Tab bgcolor
    dark: "#000", //Dialog Icon, background MuiPopover, border active thumbnail
    contrastText: "#fff", // Popover font color
  },
  info: {
    main: "#007F7B",
  },
  thumbnail: {
    highlightActive: "#fff7d0",
    bgcolorImg: "#c1c1c1",
    border: "#6f6f6f",
  },
} satisfies CustomPalette;

export const customTheme = createTheme({
  palette: customPalette,
  typography: {
    fontFamily: "Oswald,Roboto,sans-serif",
    button: {
      fontSize: "1rem",
      fontWeight: 400,
      textTransform: "none",
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: "9999",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: "xs",
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "& .MuiIconButton-root": {
            position: "absolute",
            right: 8,
            top: 8,
            color: customPalette.secondary.dark,
          },
          "& .MuiTypography-subtitle1": {
            padding: "2rem 0 0 0",
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            width: "100%",
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "1.5rem",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
          ".ValueContent-value": {
            p: {
              fontFamily: "Roboto, Oswald, sans-serif",
              fontWeight: "inherit",
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          ".MuiMenuItem-root": {
            "&.Mui-selected": {
              backgroundColor: customPalette.primary.main,
              "&.Mui-focusVisible": {
                backgroundColor: customPalette.primary.main,
              },
              "&:hover": {
                backgroundColor: customPalette.primary.main,
              },
            },
            "&.Mui-focusVisible": {
              backgroundColor: "inherit",
            },
            "&:hover": {
              backgroundColor: customPalette.primary.main,
            },
          },
        },
      },
    },
    MuiInput: {
      defaultProps: { disableUnderline: false },
      styleOverrides: {
        root: {
          "&:hover": {
            "&::before": {
              borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Roboto,Oswald,sans-serif",
        },
        input: {
          "&:not(.Mui-disabled)": {
            backgroundColor: customPalette.thumbnail.highlightActive,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: { variant: "standard" },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "text" },
          style: {
            color: customPalette.primary.contrastText,
            "&:hover": { backgroundColor: "transparent" },
          },
        },
      ],
      defaultProps: {
        variant: "contained",
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: "0 3rem",
          minHeight: "2.25rem",
        },
        startIcon: {
          position: "absolute",
          left: "1.25rem",
        },
        endIcon: {
          position: "absolute",
          right: "1.25rem",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          display: "none",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: customPalette.primary.contrastText,
          fontWeight: 500,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "&.checkbox": {
            backgroundColor: customPalette.primary.light,
            minWidth: "10rem",
            maxWidth: "20rem",
            minHeight: "5rem",
            marginLeft: "0",
            marginRight: "0",
            textAlign: "center",
            padding: "1rem",
            ".MuiBox-root": {
              ".MuiGrid-root": {
                margin: 0,
                padding: 0,
                width: "100%",
                p: {
                  "&:not(.MuiTypography-body1)": {
                    margin: "0.6rem 0 0 0",
                  },
                },
              },
            },
            "&.active": {
              backgroundColor: customPalette.primary.main,
            },
            "&:hover": {
              backgroundColor: customPalette.primary.main,
            },
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ".MuiBox-root": {
            gridTemplateColumns: "100%",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          ".ValueContent-content": {
            marginTop: "1rem",
            ".MuiCardContent-root": {
              marginTop: "-1rem",
              ".MuiGrid-root": {
                justifyContent: "left",
              },
            },
          },
          ".ValueContent-value": {
            p: {
              fontWeight: 500,
              lineHeight: 1.4,
            },
          },
          ".ValueContent-description": {
            fontFamily: "Roboto, Oswald, sans-serif",
            fontSize: "0.875rem",
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        anchorOrigin: { vertical: "top", horizontal: "center" },
        transformOrigin: { vertical: 65, horizontal: "center" },
        slotProps: {
          paper: {
            sx: {
              "&:not(.MuiMenu-paper)": {
                marginLeft: "-13px",
                overflow: "visible",
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  bottom: 0,
                  left: "calc(50% - 5px)",
                  width: 10,
                  height: 10,
                  backgroundColor: "inherit",
                  transform: "translateY(50%) rotate(45deg)",
                  boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
                },
                ".MuiTypography-root": {
                  fontFamily: "Roboto, Oswald, sans-serif",
                  fontSize: "0.75rem",
                  lineHeight: 1.4,
                },
              },
            },
          },
        },
      },
      styleOverrides: {
        paper: {
          "&:not(.MuiMenu-paper)": {
            backgroundColor: customPalette.secondary.dark,
            color: customPalette.secondary.contrastText,
            borderRadius: "0.6rem",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "transparent",
          },
          ".MuiSvgIcon-root": {
            width: "1rem",
            height: "1rem",
          },
        },
      },
    },

    MuiGrid: {
      styleOverrides: {
        root: {
          ".thumbnail-card": {
            "&.custom": {
              maxWidth: "100%",
              marginInline: "0.5rem",
            },
            ".MuiFormControl-root": {
              width: "100%",
            },
            ".MuiPaper-root": {
              boxShadow: "none",
              "&.active": {
                backgroundColor: customPalette.thumbnail.highlightActive,
                ".MuiCardMedia-root": {
                  borderColor: customPalette.thumbnail.border,
                },
              },
            },
            ".MuiCardMedia-root": {
              backgroundColor: customPalette.thumbnail.bgcolorImg,
              border: "3px solid transparent",
            },
          },
          "&.header-grid": {
            ".ValueContent-value": {
              p: {
                fontSize: "1.5rem",
                lineHeight: "1.4",
              },
            },
          },
          "&.complex_ui_configurator_sidebar": {
            borderTop: `1px solid ${customPalette.secondary.light}`,
            borderRight: `1px solid ${customPalette.secondary.light}`,
            ".attribute-wrapper": {
              borderBottom: `1px solid ${customPalette.secondary.light}`,
              paddingTop: "1rem",
              margin: 0,
              ".gridType-Attribute": {
                marginRight: "1rem",
              },
            },
          },
          "&.complex_ui_configurator_sidebar_closed": {
            borderTop: `1px solid ${customPalette.secondary.light}`,
            borderRight: `1px solid ${customPalette.secondary.light}`,
            padding: "0.5rem",
          },
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        focusHighlight: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          paddingBottom: "4rem",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-child td": {
            borderWidth: "3px",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          ".MuiTypography-root": {
            fontFamily: "Roboto, Oswald, sans-serif",
            fontSize: "0.75rem",
            fontWeight: 700,
          },
        },
        body: {
          fontFamily: "Roboto, Oswald, sans-serif",
          fontSize: "0.75rem",
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        valueLabelDisplay: "on",
      },
      styleOverrides: {
        thumb: {
          "&:hover, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: "none",
          },
        },
        valueLabel: {
          borderRadius: "5px",
        },
        track: {
          backgroundColor: "transparent",
          border: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          position: "inherit",
        },
        indicator: {
          display: "none",
        },
      },
    },
    MuiTab: {
      defaultProps: { iconPosition: "start" },
      styleOverrides: {
        root: {
          lineHeight: 1.4,
          fontWeight: 500,
          textTransform: "uppercase",
          color: customPalette.primary.contrastText,
          justifyContent: "left",
          gap: "1rem",
          paddingBlock: "1.1rem",
          maxWidth: "100%",
          maxHeight: "4.5rem",
          "&.tab-header": {
            opacity: "inherit",
            cursor: "default",
          },
          "&.complete": {
            backgroundColor: customPalette.secondary.light,
          },
          "&.Mui-selected": {
            color: customPalette.primary.contrastText,
            backgroundColor: customPalette.primary.main,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          //Autocomplete gets horizontal scroll bar at higher width
          maxWidth: "99.5%",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          ".MuiTypography-root": {
            fontFamily: "Roboto, Oswald, sans-serif",
          },
        },
      },
    },
  },
});

export const theme = responsiveFontSizes(customTheme);
